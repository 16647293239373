<template>
  <!-- 大运河企业生态服务 -->
  <div>
    <Header></Header>
    <div>
      <!-- 头部 -->
      <div class="serve">
        <div class="serve1">
          <img v-lazy='require("./img/HiAl5sW1.webp")' class="serve_img">
        </div>
        <div class="title1">
          <div class="title2">
            大运河企业生态服务
          </div>
          <!-- <div class="title3">
            项目赋能 | 金融赋能 | 咨询赋能 | 战略合作
          </div> -->
          <div class="title3">
            打造文旅发展新引擎、文旅产业新样板
            <br>
            建设中国文旅生态的智造者
          </div>
        </div>
      </div>
      <!-- 关于我们 -->
      <div class="serve21">
        <div class="serve3">关于我们</div>
        <div class="wprd15">
          江苏省文化投资管理集团有限公司成立于2015年，是省属大型国有文化企业，是江苏省推动文化事业、文化产业高质量发展的重要金融服务平台、国有资本运营管理平台、新兴文化业态培育平台和艺术经营集聚平台。集团公司注册资本为30亿元人民币，同时管理运营江苏大剧院资产、承担大运河文化带江苏段保护传承利用及文化旅游融合发展投资、受托管理政府文化类资金（基金）。
          <br>
          <br>
          集团作为国有文化企业，具有鲜明的文化、金融双重属性，坚持把社会效益放在首位、实现社会效益与经济效益相统一，坚持以市场为导向、资本为纽带、整合为手段、创新为动力，以“大剧院、大运河、大平台”三大支柱为依托，顺应互联网和文化科技发展新趋势，打造精品内容生产、文化金融服务、文旅项目运管核心竞争力，运用股权、债权、产权交易等多种手段，加强国有文化资本运营管理，促进文化资源整合和文旅融合发展，建立完善文化投融资体系和管理平台，培育新兴文化业态和骨干文化企业，推动文化艺术的生产传播展示，努力建成引领带动全省文化产业快速发展、在全国有影响力的文化投资龙头企业。
          <br>
          <br>
          集团坚持以习近平新时代中国特色社会主义思想为指导，秉承“创业创新、向上向善”的精神，改革创新、扎实有为，奋力推动高质量发展走在前列，为建设“强富美高”新江苏、谱写新时代中国特色社会主义江苏实践新篇章作出新贡献。
          <br>
        </div>
      </div>
      <!-- 高质量发展文旅科技产业 -->
      <div class="serve2" style="margin-top:-30px;">
        <div class="serve3">高质量发展文旅科技产业</div>
        <div class="serveimg">
          <div class="serveimg2">
            <img v-lazy="require('./img/001.png')">
          </div>
        </div>
        <div class="serveimg3">
          <div class="serveimg4">
            <img v-lazy="require('./img/002.png')">
          </div>
        </div>
      </div>
      <!-- 三大赋能 -->
      <div style="margin-top:50px;">
        <div class="serve3">三大赋能，促进生态伙伴发展</div>
        <div class="serve11">
          <div>
            <div class="serve5">
              <img src="./img/003.png" class="serve6">
            </div>
          </div>
        </div>
        <div class="serve12">
          <div>
            <div class="serve5">
              <img src="./img/jeSR4t7i.webp" class="serve6">
            </div>
          </div>
        </div>
      </div>
      <!-- 项目管理赋能 -->
      <div class="serve13" style="margin-top:100px;">
        <div class="serve15">
          <div class="serve16">
            项目管理赋能
          </div>
          <div class="word4">
            <div><strong>需求调研＆设计：</strong>文化和旅游行业多年深耕，建立了专业的产品经理团队、需求调研团队，精准解析行业政策，精确分析客户需求。
            </div>
          </div>
          <div class="word5">
            <div><strong>项目管理赋能：</strong>多项信息化尤其大型信息化项目操盘经验，形成了一套行之有效的项目管理、质量管理的能力体系，可以提供专业项目管理咨询培训服务，为企业项目标准化交付提供赋能。
            </div>
          </div>
        </div>
        <div>
          <div class="serveimg8">
            <img src="./img/iQPjXkTZ.webp" class="serveimg9">

          </div>
        </div>
      </div>
      <!-- 金融赋能 -->
      <div class="serve20">
        <div class="serve3">金融赋能</div>
        <div class="serve17">
          <div class="serve18">
            <div class="word6"><strong>资金授信</strong></div>
            <div class="word7">
              联合知名银行为民营企业提供最高1000万元项目专项授信，解决民营资本垫资需求。
            </div>
          </div>
          <div class="serve18" style="margin-left:50px">
            <div class="word6"><strong>融资租赁服务
              </strong></div>
            <div class="word7">
              集团旗下租赁公司，为可融资租赁项目开展合作支持。
            </div>
          </div>
        </div>
        <div class="serveimg10">
          <div class="serveimg11">
            <img v-lazy="require('./img/lPjbtH67.webp')">
            <div class="serve19">
              <div class="word8">缓解</div>
              <div class="word9">资金困扰</div>
            </div>
          </div>
        </div>

      </div>
      <!-- 资本赋能 -->
      <div style="margin-bottom:100px;margin-top:30px;">
        <div class="serve3">
          资本赋能
        </div>
        <div class="word10">
          <div class="word11">
            <strong>发挥国有企业投融资优势，甄选优质创业企业，开展资本赋能与合作。</strong>
            <br>
            随着尖端技术的发展，科技+文旅”项目的投资回报率远大于纯文旅项目，且财务表现和市场抗压能力有较大提升；
            计算机视觉识别和语义识别两大技术的合理应用，会让传统文旅景区发生质的转变；拥抱科技，夯实内容，会成为未来景区发展的新路径。
            <br>
          </div>
        </div>
        <div style="margin-top:20px">
          <div class="serveimg14">
            <img v-lazy="require('./img/zs70D4DO.webp')">
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  data() {
    return {}
  },
  components: {
    Header,
    Footer,
  },
}
</script>
<style  lang="scss" scoped>
/* 动画效果 */
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.serve {
  height: 600px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.serve_img {
  height: 600px;
  width: 100%;
}

.serve1 {
  height: 600px;
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: center;

  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }

  // 实际图片大小
  img[lazy="loaded"] {
    height: 600px;
    width: 100%;
  }
}

.title1 {
  z-index: 5;
  position: absolute;
  left: 361px;
  top: 230px;

}

.title2 {
  color: rgba(255, 255, 255, 1);
  font-size: 48px;
  //  margin-top: -370px;
  // padding-left: 360px;
  text-align: left;
}

.title2 {
  animation: sport 0.5s;
  transform: translateY(0px);
}

.title3 {
  color: #B2906A;
  font-size: 24px;
  // padding-left: 360px;
  margin-top: 24px;
  text-align: left;
  width: 675px;
}

.title3 {
  animation: sport 0.5s;
  transform: translateY(0px);
}

.title4 {
  color: rgba(255, 255, 255, 1);
  ;
  font-size: 28px;
  // padding-left: 360px;
  margin-top: 24px;
  text-align: left;
  width: 500px;
}

.title4 {
  animation: sport 0.5s;
  transform: translateY(0px);
}

//   .serve2{
//     //   height: 820px;
//   }
.serve3 {
  //   width: 248px;

  color: rgba(51, 51, 51, 1);
  font-size: 40px;
  letter-spacing: 1.25px;
  line-height: 56px;
  padding-top: 80px;
  margin: auto;
  font-weight: 400;
}

.serveimg {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

.serveimg2 {

  display: flex;
  justify-content: center;
  align-items: center;

  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }

  // 实际图片大小
  img[lazy="loaded"] {
    height: 315px;
    width: 1200px;
  }
}

.serveimg3 {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.serveimg4 {
  display: flex;
  justify-content: center;
  align-items: center;

  img[lazy="loading"] {
    width: 50px;
    height: 50px;
  }

  // 实际图片大小
  img[lazy="loaded"] {
    width: 1100px;
    height: 158px;
  }
}

.serve4 {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

.serve5 {
  width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;

  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }

  // 实际图片大小
  img[lazy="loaded"] {
    height: 400px;
    width: 1200px;
  }
}

.serve6 {
  width: 1200px;
}

.serve7 {
  //   height: 200px;
  width: 600px;
  //   margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: left
}

.serve8 {
  display: flex;
  justify-content: center;

}

.serveimg5 {
  width: 120px;
  height: 100px;
}

.word1 {
  //   color: rgba(255, 255, 25, 1);
  margin-left: 20px;
  margin-top: -5px;
  font-size: 20px;
  //  margin-top: -370px;
  // padding-left: 360px;
  text-align: left;
}

.word2 {
  margin-left: 20px;
  margin-top: 10px;
  width: 350px;
  color: #7F7F7F;
  font-size: 18px;
  //  margin-top: -370px;
  // padding-left: 360px;
  text-align: left;
}

.serve9 {
  margin-top: 60px;
  //    width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.serve10 {
  height: 200px;
  width: 600px;
  padding-left: 223px;
  display: flex;
  justify-content: center;
  align-items: left
}

.serveimg7 {
  margin-top: -50px;
  display: flex;
  justify-content: center;
}

.serveimg6 {

  display: flex;
  justify-content: center;
  align-items: center;

  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }

  // 实际图片大小
  img[lazy="loaded"] {
    height: 200px;
    width: 1200px;
  }
}

.serve11 {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

.serve12 {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.serve13 {
  margin-top: 60px;
  //  width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.serveimg8 {
  display: flex;
  justify-content: center;
  align-items: center;

  img[lazy="loading"] {
    width: 50px;
    height: 50px;
  }

  // 实际图片大小
  img[lazy="loaded"] {
    height: 200px;
    width: 600px;
  }

  .img {
    width: 600px;
    height: 200px;
  }
}

.serve15 {
  width: 600px;
}

.serve16 {
  color: rgba(51, 51, 51, 1);
  font-size: 40px;
  letter-spacing: 1.25px;
  line-height: 56px;
  //   padding-top: 10px;
  font-weight: 400;
  text-align: left;
}

.word4 {
  margin-top: 10px;
  width: 450px;
  font-size: 18px;
  text-align: left;
  line-height: 25px;
}

.word5 {
  margin-top: 30px;
  width: 450px;
  font-size: 18px;
  text-align: left;
  line-height: 25px;
}

.serveimg9 {
  width: 600px;
  height: 500px;
}

.serve17 {
  margin-top: 40px;
  margin-left: 700px;
  display: flex;
  justify-content: center;
  // flex-direction: row-reverse ;


}

.word6 {

  font-size: 20px;
  text-align: left;
  line-height: 25px;
  // font-weight: 40;
}

.word7 {
  margin-top: 20px;
  font-size: 18px;
  text-align: left;
  line-height: 30px;
}

.serve18 {
  width: 200px;

}

.serveimg10 {
  margin-top: 30px;
  position: relative;
}

.serveimg11 {
  display: flex;
  justify-content: center;
  align-items: center;

  img[lazy="loading"] {
    width: 50px;
    height: 50px;
  }

  // 实际图片大小
  img[lazy="loaded"] {
    height: 300px;
    width: 1200px;
  }
}

.serve19 {
  font-size: 60px;
  position: absolute;
  top: -110px;
  left: 500px;

}

.word8 {
  text-align: left;
}

.word10 {
  width: 1200px;
  margin: auto;
}

.word11 {
  margin-top: 40px;
  text-align: left;
  font-size: 20px;
  line-height: 30px;

}

.serveimg14 {
  display: flex;
  justify-content: center;
  align-items: center;

  img[lazy="loading"] {
    width: 100px;
    height: 100px;
  }

  // 实际图片大小
  img[lazy="loaded"] {
    height: 295px;
    width: 1200px;
  }
}

.wprd15 {
  width: 1200px;
  margin: auto;
  margin-top: 40px;
  color: #7F7F7F;
  text-align: left;
  font-size: 20px;
  line-height: 40px;

}

.serve21 {
  padding-bottom: 100px;
}
</style>
