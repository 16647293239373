var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header'),_c('div',[_c('div',{staticClass:"serve"},[_c('div',{staticClass:"serve1"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require("./img/HiAl5sW1.webp")),expression:"require(\"./img/HiAl5sW1.webp\")"}],staticClass:"serve_img"})]),_vm._m(0)]),_vm._m(1),_c('div',{staticClass:"serve2",staticStyle:{"margin-top":"-30px"}},[_c('div',{staticClass:"serve3"},[_vm._v("高质量发展文旅科技产业")]),_c('div',{staticClass:"serveimg"},[_c('div',{staticClass:"serveimg2"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('./img/001.png')),expression:"require('./img/001.png')"}]})])]),_c('div',{staticClass:"serveimg3"},[_c('div',{staticClass:"serveimg4"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('./img/002.png')),expression:"require('./img/002.png')"}]})])])]),_vm._m(2),_vm._m(3),_c('div',{staticClass:"serve20"},[_c('div',{staticClass:"serve3"},[_vm._v("金融赋能")]),_vm._m(4),_c('div',{staticClass:"serveimg10"},[_c('div',{staticClass:"serveimg11"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('./img/lPjbtH67.webp')),expression:"require('./img/lPjbtH67.webp')"}]}),_vm._m(5)])])]),_c('div',{staticStyle:{"margin-bottom":"100px","margin-top":"30px"}},[_c('div',{staticClass:"serve3"},[_vm._v("\n        资本赋能\n      ")]),_vm._m(6),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('div',{staticClass:"serveimg14"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('./img/zs70D4DO.webp')),expression:"require('./img/zs70D4DO.webp')"}]})])])])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title1"},[_c('div',{staticClass:"title2"},[_vm._v("\n          大运河企业生态服务\n        ")]),_c('div',{staticClass:"title3"},[_vm._v("\n          打造文旅发展新引擎、文旅产业新样板\n          "),_c('br'),_vm._v("\n          建设中国文旅生态的智造者\n        ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"serve21"},[_c('div',{staticClass:"serve3"},[_vm._v("关于我们")]),_c('div',{staticClass:"wprd15"},[_vm._v("\n        江苏省文化投资管理集团有限公司成立于2015年，是省属大型国有文化企业，是江苏省推动文化事业、文化产业高质量发展的重要金融服务平台、国有资本运营管理平台、新兴文化业态培育平台和艺术经营集聚平台。集团公司注册资本为30亿元人民币，同时管理运营江苏大剧院资产、承担大运河文化带江苏段保护传承利用及文化旅游融合发展投资、受托管理政府文化类资金（基金）。\n        "),_c('br'),_c('br'),_vm._v("\n        集团作为国有文化企业，具有鲜明的文化、金融双重属性，坚持把社会效益放在首位、实现社会效益与经济效益相统一，坚持以市场为导向、资本为纽带、整合为手段、创新为动力，以“大剧院、大运河、大平台”三大支柱为依托，顺应互联网和文化科技发展新趋势，打造精品内容生产、文化金融服务、文旅项目运管核心竞争力，运用股权、债权、产权交易等多种手段，加强国有文化资本运营管理，促进文化资源整合和文旅融合发展，建立完善文化投融资体系和管理平台，培育新兴文化业态和骨干文化企业，推动文化艺术的生产传播展示，努力建成引领带动全省文化产业快速发展、在全国有影响力的文化投资龙头企业。\n        "),_c('br'),_c('br'),_vm._v("\n        集团坚持以习近平新时代中国特色社会主义思想为指导，秉承“创业创新、向上向善”的精神，改革创新、扎实有为，奋力推动高质量发展走在前列，为建设“强富美高”新江苏、谱写新时代中国特色社会主义江苏实践新篇章作出新贡献。\n        "),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"50px"}},[_c('div',{staticClass:"serve3"},[_vm._v("三大赋能，促进生态伙伴发展")]),_c('div',{staticClass:"serve11"},[_c('div',[_c('div',{staticClass:"serve5"},[_c('img',{staticClass:"serve6",attrs:{"src":require("./img/003.png")}})])])]),_c('div',{staticClass:"serve12"},[_c('div',[_c('div',{staticClass:"serve5"},[_c('img',{staticClass:"serve6",attrs:{"src":require("./img/jeSR4t7i.webp")}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"serve13",staticStyle:{"margin-top":"100px"}},[_c('div',{staticClass:"serve15"},[_c('div',{staticClass:"serve16"},[_vm._v("\n          项目管理赋能\n        ")]),_c('div',{staticClass:"word4"},[_c('div',[_c('strong',[_vm._v("需求调研＆设计：")]),_vm._v("文化和旅游行业多年深耕，建立了专业的产品经理团队、需求调研团队，精准解析行业政策，精确分析客户需求。\n          ")])]),_c('div',{staticClass:"word5"},[_c('div',[_c('strong',[_vm._v("项目管理赋能：")]),_vm._v("多项信息化尤其大型信息化项目操盘经验，形成了一套行之有效的项目管理、质量管理的能力体系，可以提供专业项目管理咨询培训服务，为企业项目标准化交付提供赋能。\n          ")])])]),_c('div',[_c('div',{staticClass:"serveimg8"},[_c('img',{staticClass:"serveimg9",attrs:{"src":require("./img/iQPjXkTZ.webp")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"serve17"},[_c('div',{staticClass:"serve18"},[_c('div',{staticClass:"word6"},[_c('strong',[_vm._v("资金授信")])]),_c('div',{staticClass:"word7"},[_vm._v("\n            联合知名银行为民营企业提供最高1000万元项目专项授信，解决民营资本垫资需求。\n          ")])]),_c('div',{staticClass:"serve18",staticStyle:{"margin-left":"50px"}},[_c('div',{staticClass:"word6"},[_c('strong',[_vm._v("融资租赁服务\n            ")])]),_c('div',{staticClass:"word7"},[_vm._v("\n            集团旗下租赁公司，为可融资租赁项目开展合作支持。\n          ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"serve19"},[_c('div',{staticClass:"word8"},[_vm._v("缓解")]),_c('div',{staticClass:"word9"},[_vm._v("资金困扰")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"word10"},[_c('div',{staticClass:"word11"},[_c('strong',[_vm._v("发挥国有企业投融资优势，甄选优质创业企业，开展资本赋能与合作。")]),_c('br'),_vm._v("\n          随着尖端技术的发展，科技+文旅”项目的投资回报率远大于纯文旅项目，且财务表现和市场抗压能力有较大提升；\n          计算机视觉识别和语义识别两大技术的合理应用，会让传统文旅景区发生质的转变；拥抱科技，夯实内容，会成为未来景区发展的新路径。\n          "),_c('br')])])
}]

export { render, staticRenderFns }